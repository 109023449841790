<!-- 
选地址  根据行政区域拿取 商品价格和商品配送方式（重算金额）

商品可使用积分 = 商品数量* 商品积分价（fPointPrice） || fEndBalance（用户积分余量 ）  这两个哪个值小用哪个。
0  <= 用户输入积分 <=  商品可使用积分
积分换算率 pointRMBPrice
积分抵扣金额  积分换算率 * 用户输入积分

优惠券使用哪个就是多少优惠券抵扣金额
优惠券选择 ，当商品价格（单价*数量）

选择配送方式为承运商后 开始算运费
自提和仓库代送 运费为 0 
用户选择是否用运输发票 来判断使用含税不含税的运费价格。
fFreightPrice（不含税）、fFreightPriceTax（含税）
fFreightPrice * fPlanAmount = 最终商品运费 
 -->
<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto"
		style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;" v-loading="laoding">
		<!-- 面包屑 -->
		<!-- <breadcrumb></breadcrumb> -->
		<!-- 面包屑END -->
		<div style="height: 111px;background: #FFFFFF;width: 100%;margin-top: 10px;" class="border-F2F2F2">
			<div style="padding: 15px 140px;">
				<el-steps :active="active" :align-center="true" finish-status="success">
					<el-step :title="stepOneTitle==1?'商品详情':'购物车'"></el-step>
					<el-step title="填写订单"></el-step>
					<el-step title="支付订单"></el-step>
					<el-step title="支付结果"></el-step>
				</el-steps>
			</div>
		</div>
		<!-- <div class="" @click="toEdit">
				编辑订单
			</div> -->
		<div class="" v-loading='orderLoading'>
			<div v-for="(item, index) in orderList" :key="index">
				<div class="font-size16 font-weight700 margin-b-10 margin-t-10 color-theme">
					订单{{index+1}}
				</div>
				<div class="border-F2F2F2">
					<!-- 收货人信息 -->
					<div>
						<div
							class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-lr-15 flex-row-space-between-center">
							收货人信息</div>
						<div v-if="JSON.stringify(item.address) != '{}'">
							<div class="price-box active border-color-theme flex-row-space-between-center pointer box-sizing"
								style="background: #f8f9fe;" @mouseover="mouse = -99" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center">
									<div class=" flex-column-align-center margin-l-10 color-theme ">
										<div>{{ item.address.fLinkman }}</div>
										<div>{{ item.address.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 color-theme">工程名称：{{ item.address.fProjectName }}</div>
									<div class="margin-l-15 color-theme">收货地址：{{ item.address.fFullPathAddress }}</div>
								</div>
								<div class="padding-r-10 " v-show="mouse == -99">
									<i class="el-icon-edit font-color-666 font-size18 pointer"
										style="padding-right: 20px;" @click="EditAddress(item.address)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18 pointer" @click="DeleteAddressChecked"></i> -->
								</div>
							</div>
							<!-- 上面这部分用来显示被选中的数据,下面的位其余数据 -->
							<div class="price-box active flex-row-space-between-center box-sizing pointer-border-color-theme border-F8F9FE"
								v-for="(addressitem, addressindex) in item.noDefaultAddress" :key="addressindex"
								style="background: #f8f9fe;" v-if="addressStatus && addressIndex == index"
								@mouseover="mouse = addressindex" @mouseout="mouse = -1">
								<div class="font-size14  flex-row-align-center font-color-999"
									@click="addressChoose(addressitem, index)">
									<div class=" flex-column-align-center margin-l-10 font-color-999">
										<div class="">{{ addressitem.fLinkman }}</div>
										<div class="">{{ addressitem.fLinkPhone }}</div>
									</div>
									<div class="margin-l-17 font-color-999">工程名称：{{ addressitem.fProjectName }}</div>
									<div class="margin-l-15 font-color-999">收货地址：{{ addressitem.fFullPathAddress }}
									</div>
								</div>
								<div class="padding-r-10" v-show="mouse == addressindex">
									<i class="el-icon-edit font-color-666 font-size18" style="padding-right: 20px;"
										@click="EditAddress(addressitem)"></i>
									<!-- <i class="el-icon-delete font-color-666 font-size18" @click="DeleteAddress(addressitem, addressindex)"></i> -->
								</div>
							</div>
							<div class="margin-lr-15 flex-row-align-center">
								<div class="pointer margin-r-10" @click="noDefaultAddressShow(index)">
									{{ addressStatus && addressIndex == index ? '收起地址' : '展开地址' }}
									<i class=" "
										:class="addressStatus && addressIndex == index ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
								</div>
								<span
									class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5"
									@click="addAddress">+新增收货地址</span>
							</div>
						</div>
						<div v-else>
							<div class="price-box active flex-row-space-between-center box-sizing padding-lr-10 font-color-999"
								style="background: #f8f9fe;">
								暂无可用收货地址
							</div>
							<div class="padding-lr-13 margin-t-15 margin-b-15">
								<span
									class="pointer color-theme border-color-theme padding-lr-10 font-size13 padding-tb-5"
									@click="addAddress">+新增收货地址</span>
							</div>
						</div>
					</div>
					<!-- 收货人信息结束 -->
					<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-l-15">商品信息</div>
					<div class=" margin-lr-15">
						<div class="flex-row-space-between-center font-size14"
							style="height: 35px;background: #f8f9fe;">
							<p class="width756" style="text-align: center;">商品信息</p>
							<p class="width156" style="text-align: center;">数量</p>
							<p class="width156" style="text-align: center;">单价</p>
						</div>
						<div class="wrap">
							<div class=" ">
								<div class="">
									<div class="flex-row-space-between-center" style="height: 123px;">
										<div class="flex-row-start-start">
											<div class="flex-row-space-between-center" style="margin-left: 15px;">
												<div class="box-shadow-all imageRadius" style="">
													<el-image class="imageRadius" :src="item.fPicturePath"></el-image>
												</div>
											</div>
											<div style="margin-left: 19px;">
												<div class="">
													<div class="tag-box font-size12 font-color-fff radius2 margin-r-5"
														style="display: inline-block;" v-if="item.fShopTypeID == 1">
														{{ item.fShopTypeName }}
													</div>
													{{ item.fGoodsFullName }}
												</div>
												<div class="flex-row" style="margin-top: 8px;">
													<div class="tag-box-red  font-size12 " v-if="item.text">
														{{ item.text }}
													</div>
													<div class="tag-box-blue color-theme border-theme font-size12"
														v-if="item.dei">{{ item.dei }}</div>
												</div>
												<!-- <div>工程：达坂城第一工程</div> -->
											</div>
										</div>
										<div class=" ">
											<!-- item.fPlanAmount:{{item.fPlanAmount}}
											item.fMinSaleAmount:{{item.fMinSaleAmount}}
											item.fAdjustmentRange:{{item.fAdjustmentRange}}
											item.fPrecision:{{item.fPrecision}} -->
											<div class="flex-row">
												<div class="flexUD  width156"
													style="border-left: 1px solid #eaebef;height: 65px;">

													<el-input-number size="small" v-model="item.fPlanAmount"
														:min="item.fMinSaleAmount" :max="99999" :step="item.fAdjustmentRange"
														:precision="item.fPrecision" @change="valChange(item,index)"
														@blur="valBlur(item,index)" @focus="getInputFocus($event)">
													</el-input-number>
												</div>
												<div class="flexUD  width156"
													style="border-left: 1px solid #eaebef;height: 65px;">
													<p class="font-weight700">￥<span
															v-if='item.fGoodsMinPrice!="" &&item.fGoodsMinPrice == item.fGoodsPrice ? false:true'>{{item.fGoodsMinPrice}}~</span>{{item.fGoodsPrice}}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div class="padding-lr-15" style="padding-top: 6px;padding-bottom: 2px;">
										<el-row>
											<el-col :span="7">
												<div class="flex-row-align-center"
													style="align-items: center;height:30px">
													配送方式：
													<el-radio-group v-model="item.fDeliveryID"
														@change="deliveryMethodClick(item.fDeliveryID,index)">
														<el-radio :label="item2.fDeliveryID"
															v-for="(item2, index2) of item.deliveryDtos">
															{{item2.fDelivery}}
														</el-radio>
													</el-radio-group>
												</div>
											</el-col>
											<el-col :span="8" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center">
													承运商：
													<el-select style="margin-left: 40px;" v-model="item.carrierData"
														size="mini" @change="carrierClick(item.carrierData,index)">
														<el-option v-for="(item4, index4) in item.carrierDtos"
															:key="index4" :label="item4.fCustomerName"
															:value="item4.fCarrierID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="8" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center font-color-FF0000 font-weight700"
													style="float:right;color:black">
													运费单价:￥ <span
														v-if='item.fFreightPriceTax == 0 || item.carrierData == -1'>--</span><span
														v-else>{{item.fFreightPriceTax}}</span>
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15" style="padding-top: 6px;padding-bottom: 7px;">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center">
													运输方式：
													<el-select v-model="item.fBusinessTypeID" size="mini"
														@change="BusinessTypeChoose(index)">
														<el-option v-for="(item7, index7) in item.BusinessType"
															:key="index7" :label="item7.fBuTypeName"
															:value="item7.fBusinessTypeID"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="7" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center">
													开具运输发票：
													<el-select v-model="item.fIsNeedFInvoiceStatus" size="mini"
														@change="transportationInvoiceClick(item.fIsNeedFInvoiceStatus,index)">
														<el-option
															v-for="(item7, index7) in item.transportationInvoiceDtos"
															:key="index7" :label="item7.title" :value="item7.id">
														</el-option>
													</el-select>
													<!-- <el-checkbox v-model="item.transportationInvoiceData" @change="transportationInvoiceClick(item,index)">开具运输发票</el-checkbox> -->
												</div>
											</el-col>
											<el-col :span="6" v-if="item.fDeliveryID == 1">
												<div class="flex-row-align-center font-color-999 font-size12"
													style="height: 30px;">
													<span
														class="iconfont icon-tishi5 font-color-FFAA00 margin-r-5"></span>
													运费单价,不含装卸等其他费用
												</div>
											</el-col>
										</el-row>
									</div>
									<div class="margin-lr-15 bg-F8F9FE ">
										<el-row :gutter="0">
											<el-col :span="7">
												<div class="flex-row-align-center">
													<div style="width: 70px;">优惠券：</div>
													<!-- fIsEnableUseCoupon==1可以使用优惠券，0不能使用优惠券 -->
													<el-select v-model="item.fCouponCustomerReceiveRecordID" size="mini"
														@change="couponClick(item.fCouponCustomerReceiveRecordID,index)"
														:clearable="true"
														@clear="couponClear(item.fCouponCustomerReceiveRecordID,index)"
														:disabled="item.fIsEnableUseCoupon == 0 || (item.fCouponCustomerReceiveRecordID == '暂无可用' && item.couponList.length == 0)">
														<el-option v-for="(item5,index5) in item.couponList"
															:key="index5"
															:label="item5.fCouponCustomerReceiveRecordID!='-1'?'满' + item5.fMinSaleMoney + '减' + item5.fDeductionMoney:item5.fCouponName"
															:value="item5.fCouponCustomerReceiveRecordID"
															:disabled="item5.isUse"></el-option>
													</el-select>
												</div>
											</el-col>
											<el-col :span="6">
												<div class="flex-row">
													<div class="flex-row-align-center">
														使用积分：
														<!-- (可使用：{{ calculationEndBalance(item) }}) -->
														<input style="margin-left: 27px;" class="inputStyle"
															v-model="item.fUsePoint" @blur="inputBlur(item,index)"
															@input="inputInput(item.fUsePoint,index)" />
													</div>
												</div>
											</el-col>
											<el-col :span="10">
												<div class="flex-row-align-center font-color-999 font-size12"
													style="height: 30px;">
													<div
														class="font-size12 color-theme flex-row-space-between-center margin-r-10 ">
														<span class="iconfont icon-jifen1 color-theme"></span>
														- ¥{{item.deductionMoney | NumFormat }}
													</div>
													<span
														class="iconfont icon-tishi5 font-color-FFAA00 margin-r-5"></span>
													您当前店铺积分余额为{{ originalPoint }},本商品最多可使用{{calculationEndBalance(item)}}积分
												</div>
											</el-col>
										</el-row>
									</div>
									<!-- <div class="margin-lr-15 margin-t-10">
										<el-row :gutter="24">
											<el-col :span="8">
												<div class="flex-row-align-center">
													开具运输发票：
													<el-select v-model="item.fIsNeedFInvoiceStatus" size="mini" @change="transportationInvoiceClick(item.fIsNeedFInvoiceStatus,index)">
														<el-option v-for="(item7, index7) in item.transportationInvoiceDtos" :key="index7" :label="item7.title"
														 :value="item7.id"></el-option>
													</el-select>
												</div>
											</el-col>
										</el-row>
									</div> -->
								</div>
								<div style="padding-top: 8px;" class="divcss5-3"></div>
								<div style="height: 134px;">
									<div class="flex-column-start-end font-size12 "
										style="margin-right: 15px;line-height: 22px;">
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											货款金额：
											<div><span
													v-if='item.fGoodsPrice == item.fGoodsMinPrice && item.deliveryDtos && item.deliveryDtos.length != 0 ?true:false'>+
													¥{{ (item.fGoodsPrice * 100 * item.fPlanAmount)/100 | NumFormat  }}</span><span
													v-else>+ ¥{{item.fGoodsPrice * 0 | NumFormat}}</span> </div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											运费金额：
											<div> <span
													v-if='item.fGoodsPrice == item.fGoodsMinPrice && item.deliveryDtos && item.deliveryDtos.length != 0  ?true:false'>+
													¥{{item.freightAmount | NumFormat }}</span><span v-else>+
													¥{{item.freightAmount | NumFormat }}</span> </div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											优惠券抵扣：
											<div><span
													v-if='item.fGoodsPrice == item.fGoodsMinPrice && item.deliveryDtos && item.deliveryDtos.length != 0 ?true:false'>-
													¥{{item.fDeductionMoney | NumFormat }}</span> <span v-else>-
													¥{{item.fDeductionMoney | NumFormat }}</span> </div>
										</div>
										<div class="font-color-999 flex-row-space-between-center" style="width: 180px;">
											积分抵扣：
											<div> <span
													v-if='item.fGoodsPrice == item.fGoodsMinPrice && item.deliveryDtos && item.deliveryDtos.length != 0 ?true:false'>-
													¥{{item.deductionMoney | NumFormat }}</span><span v-else>-
													¥{{item.deductionMoney * 0 | NumFormat }}</span></div>
										</div>
										<div class="flex-row-space-between-center" style="width: 180px;">
											<div>小计：</div>
											<div class="font-color-FF0000 font-weight700"> <span
													v-if='item.fGoodsPrice == item.fGoodsMinPrice && item.deliveryDtos && item.deliveryDtos.length != 0 ?true:false'>¥{{ getOrderPrice(item)  | NumFormat}}</span><span
													v-else>¥{{ getOrderPrice(0)  | NumFormat}}</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 发票信息 -->
			<div class="flex-row">
				<div class="font-size14 font-weight700 margin-b-10 margin-t-10 margin-r-10">发票信息</div>
				<div class="flex-row-align-center border-box">
					开具货款发票：
					<!-- <el-select v-model="fIsNeedInvoiceStatus" size="mini" @change="paymentForGoodsInvoiceClick(fIsNeedInvoiceStatus)">
						<el-option v-for="(item, index) in fIsNeedInvoiceStatusList" :key="index" :label="item.title" :value="item.id"></el-option>
					</el-select> -->
					<el-radio v-model="fIsNeedInvoiceStatus" :label="1">需要</el-radio>
					<el-radio v-model="fIsNeedInvoiceStatus" :label="0">不需要</el-radio>
				</div>
			</div>

			<div class="border-F2F2F2">
				<div class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5"
					style="background-color: #F2F5FF;height: 31px;border-radius: 1px;"
					v-if="invoiceInformation.feMail!=null">
					<div class="flex-row-align-center">
						<i class="el-icon-s-ticket font-size18"></i>
						<p class="font-size14" style="padding-left: 6px;">发票信息</p>
						<!-- <p class="font-size14">(发送至{{invoiceInformation.feMail}})</p> -->
					</div>
					<i class="el-icon-arrow-up font-size18 pointer"
						:class="invoiceInformation.invoiceInformationTipsShow?'el-icon-arrow-up':'el-icon-arrow-down'"
						@click="invoiceInformationTipsShowClick"></i>
				</div>
				<div v-else class="flex-row-space-between-center color-theme padding-left-right-26 bg-fff padding-lr-5"
					style="background-color: #F2F5FF;height: 31px;border-radius: 1px;">
					<div class="flex-row-align-center"><a class="font-size14 pointer hover" style="padding-left: 6px;"
							@click="addInvoice('Add')">+维护发票收件地址</a></div>
				</div>

				<div class="font-size12 font-color-666 bg-fff" style="padding: 10px 21px;"
					v-if="invoiceInformation.invoiceInformationTipsShow">
					<div class="margin-b-5">
						<div class="flex-row line-height22">
							<div>发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row line-height22">
							<div>发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row"
							:class="getUserInfo.fCommerceTypeID=='1'?'margin-b-10 padding-b-10 border-bottom-F2F2F2':'line-height22'">
							<div>{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row line-height22">
								<div>住所：</div>
								<div>{{invoiceInformation.fAddress}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row line-height22">
								<div>开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row margin-b-10 padding-b-10 border-bottom-F2F2F2">
								<div>银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<div class="flex-row-space-between">
							<div>
								<div class="flex-row">
									<div>邮寄地址：</div>
									<div>{{invoiceInformation.fFullPathAddress}}</div>
								</div>
								<div class="flex-row line-height22">
									<div>收件人：</div>
									<div>{{invoiceInformation.fLinkman}} {{invoiceInformation.fLinkPhone}}</div>
								</div>
								<div class="flex-row">
									<div>邮箱：</div>
									<div>{{invoiceInformation.feMail}}</div>
								</div>
							</div>
							<div class="flex-row-align-center pointer hover color-theme" @click="addInvoice('Edit')">
								修改发票收件地址
							</div>
						</div>

					</div>
				</div>
			</div>
			<!-- 发票信息结束 -->
			<div class="flex-row-right bottomBox font-size16"
				style="line-height: 30px;margin-top: 15px;margin-bottom: 50px;">
				<!-- <div class="flex-row-space-between-center font-size14 font-color-666"> -->
				<div class="flex-row-center-center" style="margin-right: 30px;">
					<div class="flex-row-align-center">
						<div class="flex-row">
							<p class="font-color-FF0000 ">{{ orderList.length }}</p>
							<p class="font-color-999">件商品</p>
							<p class="font-color-000">，实付金额：</p>
							<p class="font-color-FF0000 font-weight700">¥{{ getOrderTotal() | NumFormat  }}</p>
						</div>
						<el-button v-if='isPrice' style="width: 160px;height: 40px;margin: 15px;"
							class="background-color-theme pointer" :loading="createLoading" @click="asktalkAboutPrice">
							运费议价</el-button>
						<el-button style="width: 160px;height: 40px;margin: 15px;"
							class="background-color-theme pointer" :loading="createLoading" @click="createOrder">提交订单
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 收货地址 -->
		<el-dialog v-if="addVisible" v-dialogDrag :close-on-press-escape="false" :title="pageTitle + '收货地址'"
			:visible.sync="addVisible" @close="handleClose" width="600px" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<el-form ref="addressForm" :rules="rules" :model="addressForm" label-width="130px"
					label-position="right">
					<el-form-item label="收货人" prop="fLinkman">
						<el-input v-model="addressForm.fLinkman" placeholder="请输入收货人"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="fLinkPhone">
						<el-input v-model="addressForm.fLinkPhone" maxlength="11" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="行政区域" class="areaBox" prop="area">
						<el-col :span="24">
							<selectArea v-model="addressForm.area"></selectArea>
						</el-col>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addressForm.fAddress" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="工程名称" prop="fProjectName">
						<el-input v-model="addressForm.fProjectName" placeholder="请输入工程名称"></el-input>
					</el-form-item>
					<!-- <el-form-item v-if="addressForm.fAddressTypeID == 1 && addressAddIsHaveEmail == true" label="电子邮箱" :prop="addressForm.fAddressTypeID == 1?'feMail':''">
						<el-input v-model="addressForm.feMail" placeholder='请输入电子邮箱' />
					</el-form-item> -->
					<el-form-item label="设置默认地址" prop="addressType">
						<el-switch v-model="addressForm.fIsDefault"></el-switch>
					</el-form-item>
					<el-form-item>
						<div id="container" style="height: 200px;" v-show="mapShow"></div>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="handleClose">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForm('addressForm')">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 维护发票信息 -->
		<el-dialog v-dialogDrag :close-on-press-escape="false" :title="invoiceType=='Add'?'维护':'编辑'+'发票收件地址'"
			:visible.sync="addInvoiceStatus" width="600px" :before-close="addInvoiceClose" :close-on-click-modal="false"
			class="invoice">
			<div class=" " style="">
				<div class="font-size12 font-color-666 bg-F2F4FD" style="margin-bottom: 5px;">
					<div class="invoice-box" style="line-height: 22px;padding: 10px 15px;">
						<div class="font-size14 font-weight700 font-color-000">
							{{getUserInfo.fCommerceTypeID=='1'?'个人：':'企业：'}}发票信息
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票类型：</div>
							<div>{{invoiceInformation.fInvoiceClassName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">发票抬头：</div>
							<div>{{invoiceInformation.fUnitName}}</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">{{getUserInfo.fCommerceTypeID=='1'?'身份证号：':'企业税号：'}}</div>
							<div>{{invoiceInformation.fTaxAccounts}}</div>
						</div>
						<div v-if="getUserInfo.fCommerceTypeID=='2'">
							<div class="flex-row">
								<div style="width: 80px;">住所：</div>{{invoiceInformation.fAddress}}
							</div>
							<div class="flex-row">
								<div style="width: 70px;">公司电话：</div>
								<div>{{invoiceInformation.fPhone}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">开户银行：</div>
								<div>{{invoiceInformation.fBankNames}}</div>
							</div>
							<div class="flex-row">
								<div style="width: 70px;">银行账号：</div>
								<div>{{invoiceInformation.fBankAccounts}}</div>
							</div>
						</div>
						<!-- <div class="flex-row">
							<div style="width: 70px;">邮寄地址：</div>
							<div>新疆乌鲁木齐市新市区经济开发区卫星路 499号秦郡大厦B座501室</div>
						</div>
						<div class="flex-row">
							<div style="width: 70px;">收件人：</div>
							<div>王俊凯 16612345789</div>
						</div> -->
					</div>
				</div>
				<el-form ref="addInvoiceForm" :rules="rules" :model="addInvoiceForm" label-width="140px"
					label-position="right">
					<el-form-item label="收件邮箱" prop="feMail">
						<el-input v-model="addInvoiceForm.feMail" />
					</el-form-item>
					<el-form-item label="收件姓名" prop="fLinkman">
						<el-input v-model="addInvoiceForm.fLinkman" />
					</el-form-item>
					<el-form-item label="收件电话" prop="fLinkPhone">
						<el-input v-model.number="addInvoiceForm.fLinkPhone" maxlength="11" />
					</el-form-item>
					<el-form-item label="邮寄地址" prop="area">
						<div class="flex-row">
							<selectArea v-model="addInvoiceForm.area"></selectArea>
						</div>
					</el-form-item>
					<el-form-item label="详细地址" prop="fAddress">
						<el-input v-model="addInvoiceForm.fAddress" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" @click="addInvoiceStatus = false">取 消</el-button>
				<el-button size="small" type="primary" @click="invoiceSubmitForm('addInvoiceForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import selectArea from '@/components/selectArea/selectArea.vue';
	export default {
		components: {
			breadcrumb,
			selectArea
		},
		data() {
			// const rulesloginId = (rule, value, callback) => {
			// 	if (!/^1[34578]\d{9}$/.test(value)) {
			// 		callback('手机号不正确')
			// 	} else {
			// 		callback()
			// 	}
			// }
			const rulesloginId = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else {
					let phoneNumberRul =
						/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
					if (phoneNumberRul.test(value)) {
						callback();
					} else {
						callback(new Error('请输入正确的手机号码'));
					}
				}
			}
			return {
				orderLoading: false,
				isPrice: false,
				createLoading: false,
				laoding: false,
				fFreightPrice: 0,
				orderList: [], //订单列表
				goodsOrder: [], //获取商品价格和概要信息需要的参数
				useGoodsInformation: [], //组织需要的参数
				invoiceInformation: {}, //发票信息
				chooseAddress: {}, //选中默认地址
				originalPoint: '', // 用户积分余量

				fGovernmenAreaID: ['65', '6501', '650104', '650104008'],
				addInvoiceStatus: false,
				addInvoiceForm: {
					feMail: "", // 收件邮箱
					fLinkman: '', //收件姓名
					fLinkPhone: '', //手机号码
					fAddress: "", //详细地址
					area: ['', '', '', '']
				},
				invoiceType: '', //发票收件信息新增还是编辑
				mouse: -1,

				addVisible: false,
				mapShow: false, //地图
				maplat: '',
				maplng: '',
				addr: '新疆乌鲁木齐市新市区经济开发区卫星路499号秦郡大厦B座501室',
				pageTitle: "新增",
				addressForm: {
					fLinkman: '',
					fLinkPhone: '',
					area: ['', '', '', ''],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: '1',
					addressType: '收货地址',
					fIsDefault: false
				},
				rules: {
					fLinkman: [{
							required: true,
							message: '请输入收货人',
							trigger: 'blur'
						},
						{
							min: 2,
							max: 20,
							message: '长度在 2 到 20 个字符',
							trigger: 'blur'
						}
					],
					area: [{
						required: true,
						message: '请选择行政区域',
						trigger: 'blur'
					}],
					fLinkPhone: [{
							required: true,
							message: '请输入手机号码',
							trigger: 'change'
						},
						{
							validator: rulesloginId,
							trigger: 'blur'
						}
					],
					feMail: [{
							required: true,
							message: '请填写邮箱地址',
							trigger: 'blur'
						},
						{
							type: 'email',
							message: '请填写正确的邮箱地址',
							trigger: ['blur', 'change']
						}
					],
					fAddress: [{
						required: true,
						message: '请填写详细地址',
						trigger: 'blur'
					}],
					fProjectName: [{
						required: true,
						message: '请填写工程名称',
						trigger: 'blur'
					}]
				},
				fIsNeedInvoiceStatus: 1, //是否开具货款发票
				fIsNeedInvoiceStatusList: [{
						title: '是',
						id: 1
					},
					{
						title: '否',
						id: 0
					}
				],

				addressStatus: false, //除了默认地址以外其他地址是否显示
				addressIndex: 0, //除了默认地址以外显示哪一个商品的其他地址

				addressTol: [], // 收货地址列表
				active: 2,
				stepOneTitle: '', //订单第一步是什么

				couponList: [], //优惠券列表
				initCouponList: [], // 优惠券初始列表（回填数据）
				useCouponList: [], //使用优惠券列表（修改数据）

				// 收货地址是否维护 邮箱
				addressAddIsHaveEmail: false,

				// 提交数组
				submitList: [],
				//承运商议价所需数组
				submitListPrice: [],
				// 已提交下标数组
				submitListIndex: 0,
				needPriceIndex: 0,
				// 提交成功数组
				submitSuccessList: [],
				//判断议价数据是否有成功的
				submitSuccessListPrice: [],
				resultdata: [], //议价成功后跳转的数据
				orderListData: null,
			};
		},
		async mounted() {
			this.fIsNeedInvoiceStatus = this.getUserInfo.fCommerceTypeID == '1' ? 0 :
				1; //fCommerceTypeID == '1'个人用户默认不开发票  fCommerceTypeID == '2'企业用户默认开发票
			this.stepOneTitle = this.$route.query.id; //id:1是商品详情跳转，2是购物车跳转，3是订单跳转
			this.getAddressType();
			let data = await this.$store.getters.getCommonData;
			data.forEach((item, index) => {
				let obj = {
					id: item.fGoodsID
				};
				let obj1 = {
					index: index,
					addressId: '', // 地址类型ID
					fGovernmenAreaID: -1, // 行政区域ID
					goodsId: item.fGoodsID, // 商品OD
					deliveryId: item.fDeliveryID, // 配送方式ID
					fShopUnitID: item.fShopUnitID, // 店铺ID
					fPlanAmount: item.fAmount // 商品数量
				};
				this.goodsOrder.push(obj);
				this.useGoodsInformation.push(obj1);
			});
			// 查询用户已启用收货地址
			// this.getProjectList();
			this.getList();
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			calculationEndBalance() {
				return item => {
					return Math.floor(item.fPlanAmount * item.fPointPrice > item.fEndBalance ? item.fEndBalance : item
						.fPlanAmount * item.fPointPrice)
				}
			}
		},
		methods: {
			//获取全部信息
			getList() {
				console.error(this.goodsOrder)
				// return
				let param = {
					fAddressId: [0],
					addressTypeId: 1,
					fGoodsID: [],
					fGovernmenAreaID: -1
				}
				this.goodsOrder.map(item => {
					param.fGoodsID.push(item.id)
				})
				this.ApiRequestPost('/api/mall/ebsale/order/new-init-order-logic', param).then(
					res => {
						console.log(res)
						// res.obj.couponInfoAvailableDtos = res.obj.fCoupon
						this.orderListData = res
						res.obj.items = res.obj.fAddress
						res.obj.goodList = []
						res.obj.fGoodsDeliverys = []
						res.obj.freightApply = []
						res.obj.goodsPrice = []
						res.obj.fGoodsList.map(item => {
							res.obj.goodList.push(item.fGoods)
							item.fGoodsDeliverys.map(cell => {
								if (cell.fPriceDelivery && cell.fPriceDelivery != '') {
									res.obj.fGoodsDeliverys.push(cell.fPriceDelivery)
								}
								if (cell.freightApply && cell.freightApply != '') {
									res.obj.freightApply.push(cell.freightApply)
								}
								if (cell.fPrice && cell.fPrice != '') {
									res.obj.goodsPrice.push(cell.fPrice)
								}
							})
						})
						res.obj.fTransport = res.obj.fGoodsList[0].fTransport
						// res.obj.fGoodsDeliverys = res.obj.fGoodsList[0].fGoodsDeliverys
						this.getProjectList(res)
					},
					rej => {}
				);
			},
			// 获取地址类型 判断收货地址是否显示 邮箱
			getAddressType() {
				// api/mall/ebbase/address-type/get-list
				this.ApiRequestPost('api/mall/ebbase/address-type/get-list', {}).then(
					res => {
						res.obj.items.forEach(item => {
							if (item.fAddressTypeID.toString() == "1") {
								if (item.fMustEmail.toString() == "0") {
									this.addressAddIsHaveEmail = false
								} else {
									this.addressAddIsHaveEmail = true
								}
							}
						})
					},
					rej => {}
				);
			},
			// 当前订单应支付金额
			getOrderPrice(item) {
				return (item.fGoodsPrice * 100 * item.fPlanAmount) / 100 - item.deductionMoney - item.fDeductionMoney +
					item.freightAmount
			},
			// 订单总额
			getOrderTotal() {
				let total = 0;
				this.orderList.forEach(item => {
					if (item.deliveryDtos && item.fGoodsMinPrice && item.fGoodsPrice) {
						if (item.fGoodsMinPrice != item.fGoodsPrice || item.deliveryDtos.length == 0) {
							total = total + this.getOrderPrice(item) * 0
						} else {
							total = total + this.getOrderPrice(item)
						}
					}
				})
				return total
			},
			//获取地址列表
			getProjectList(result) {
				console.log(result)
				if (result && result.obj.items && result.obj.items.length > 0) {
					// 收货地址
					this.addressTol = result.obj.items;

					// 默认地址
					let defaultaddress = {};
					let haveDefaultAddress = false;
					result.obj.items.forEach((item, index) => {
						// 斜杠去掉换为空格
						item.fFullPathAddress = item.fFullPathAddress.replace(/\\/g, '');
						// 是否默认地址
						if (item.fIsDefault == 1) {
							defaultaddress = item;
							haveDefaultAddress = true;
						}
					});
					if (haveDefaultAddress == true) {
						this.chooseAddress = defaultaddress;
					} else {
						this.chooseAddress = result.obj.items[0];
					}

					for (let item of this.useGoodsInformation) {
						item.addressId = this.chooseAddress.fAddressID;
						item.fGovernmenAreaID = this.chooseAddress.fGovernmenAreaID;
					}
				}
				console.error(this.useGoodsInformation)
				this.getOrder(result);
			},
			//获取订单
			getOrder(res) {
				this.orderList = [];
				this.useGoodsInformation.forEach((item, index) => {
					res.obj.goodList.forEach((childitem, childindex) => {
						childitem.address = this.chooseAddress;
					});
					res.obj.goodList[item.index].noDefaultAddress = this.addressTol;
					res.obj.goodList[item.index].deductionMoney = 0; // 抵扣多少钱
					res.obj.goodList[item.index].fMinSaleMoney = 0; // 优惠券能使用的最小消费金额
					res.obj.goodList[item.index].fDeductionMoney = 0; // 优惠券可抵扣金额
					res.obj.goodList[item.index].fPlanAmount = item.fPlanAmount; // 商品数量
					res.obj.goodList[item.index].fFreightPriceTax = 0; // 需要运输发票之后含税运费
					res.obj.goodList[item.index].fIsNeedFInvoiceStatus = 0; // 是否需要运输发票0不需要1需要
					res.obj.goodList[item.index].transportationInvoiceDtos = [{
							title: '需要',
							id: 1
						},
						{
							title: '不需要',
							id: 0
						}
					];
					res.obj.goodList[item.index].isClick = 0; //优惠券是否手动触发不使用默认非手动触发0
					res.obj.goodList[item.index].couponList = [];
					res.obj.goodList[item.index].invoiceInformation = {};
					res.obj.goodList[item.index].BusinessType = [];
					res.obj.goodList[item.index].fBusinessTypeID = '1'; // 运输方式ID
				});
				this.orderList = res.obj.goodList;
				console.log(this.orderList, '获取订单')
				console.error(res)
				// 获取运输方式 批量
				this.getBusinessTypeBatch(res)
				// 获取发票信息
				this.getInvoiceInfo(res);
				// 获取商品价格
				// this.getGoodsPrice();
				// 根据商品ID查询当前商品配送方式  需要地址ID  承运商获取也需要地址ID
				this.getDeliveryByAreaid(res);
				// 获取用户积分
				this.getCustomerPoint(res);
			},
			// 获取运输方式 批量订单
			async getBusinessTypeBatch(res) {
				this.laoding = true;
				let goodsIDList = []
				this.useGoodsInformation.forEach((item, index) => {
					goodsIDList.push({
						goodsId: item.goodsId
					})
				});
				res.obj.fTransport.forEach((data, dataIndex) => {
					this.orderList.forEach((item, itemIndex) => {
						if (data.goodsid == item.fGoodsID) {
							// 查找汽运 并将其放置数组第一列
							let findData = data.list.find(findItem => findItem.fBusinessTypeID == "1")
							data.list.splice(data.list.indexOf(findData), 1);
							data.list.unshift(findData);
							this.orderList[itemIndex].BusinessType = data.list;
							// this.orderList[itemIndex].fBusinessTypeID = "1";
						}
					})
				})
				this.$forceUpdate();
				this.laoding = false;
			},
			// 选择运输方式
			BusinessTypeChoose(index) {
				this.$forceUpdate()
			},

			//获取发票信息
			async getInvoiceInfo(res) {
				this.getInvoiceAddress(res);
				if (res.obj.fInvoiceInfo.fApproveStatus != 254) {
					this.tipsInfo('发票未审核通过')
					return
				}
				this.invoiceInformation.fUnitName = res.obj.fInvoiceInfo.fUnitName;
				this.invoiceInformation.fTaxAccounts = res.obj.fInvoiceInfo.fTaxAccounts;
				this.invoiceInformation.fAddress = res.obj.fInvoiceInfo.fAddress.replace(/\\/g, '');
				this.invoiceInformation.fPhone = res.obj.fInvoiceInfo.fPhone;
				this.invoiceInformation.fBankNames = res.obj.fInvoiceInfo.fBankNames;
				this.invoiceInformation.fBankAccounts = res.obj.fInvoiceInfo.fBankAccounts;
				this.invoiceInformation.fInvoiceClassName = res.obj.fInvoiceInfo.fInvoiceClassName;
			},
			//获取发票地址
			async getInvoiceAddress(res) {
				console.error(res)
				if (res && res.obj.finvoiceAddress.fFullPathAddress != null) {
					res.obj.finvoiceAddress.area = ['', '', '', ''];
					res.obj.finvoiceAddress.area[0] = res.obj.finvoiceAddress.fFullIDPath.substring(1, 3);
					res.obj.finvoiceAddress.area[1] = res.obj.finvoiceAddress.fFullIDPath.substring(4, 8);
					res.obj.finvoiceAddress.area[2] = res.obj.finvoiceAddress.fFullIDPath.substring(9, 15);
					res.obj.finvoiceAddress.area[3] = res.obj.finvoiceAddress.fFullIDPath.substring(16, 29);
					this.addInvoiceForm = res.obj.finvoiceAddress;
					this.invoiceInformation.feMail = res.obj.finvoiceAddress.feMail;
					this.invoiceInformation.fLinkman = res.obj.finvoiceAddress.fLinkman;
					this.invoiceInformation.fLinkPhone = res.obj.finvoiceAddress.fLinkPhone;
					this.invoiceInformation.fFullPathAddress = res.obj.finvoiceAddress.fFullPathAddress.replace(/\\/g,
						'');
				} else {}
			},

			//发票信息显示
			invoiceInformationTipsShowClick() {
				this.invoiceInformation.invoiceInformationTipsShow = !this.invoiceInformation.invoiceInformationTipsShow;
				this.$forceUpdate();
			},


			//获取商品价格
			async getGoodsPrice(res) {
				let apiData = [];
				console.log(this.useGoodsInformation, '查找配送方式id')
				this.useGoodsInformation.forEach(item => {
					apiData.push({
						fGovernmenAreaID: item.fGovernmenAreaID, //不根据区域定价，区域id传-1
						goodsID: item.goodsId,
						deliveryId: item.deliveryId
					})

				})
				if (res) {
					res.obj.goodsPrice.forEach((item, index) => {
						this.orderList.forEach((childitem, childindex) => {
							if (item.fGoodsId == childitem.fGoodsID) {
								childitem.fGoodsPrice = item.fGoodsPrice; // 商品价格
								childitem.fGoodsMinPrice = item.fGoodsMinPrice;
								if (childitem.fGoodsPrice != childitem.fGoodsMinPrice || childitem
									.deliveryDtos.length == 0) {
									this.$message('商品不在当前收货地址销售，请重新选择收货地址');
								}
								console.log(childitem)
								console.log(childitem.carrierDtos)
								if (childitem.fDeliveryID == 1) {
									this.isPrice = true
								} else if (childitem.fDeliveryID == 5 && childitem.carrierDtos.length > 0) {
									childitem.fGoodsMinPrice = Number(childitem.fGoodsMinPrice) +
										Number(childitem.carrierDtos[1].fFreightPriceTax) + Number(
											childitem.carrierDtos[1].fMoney)
									childitem.fGoodsPrice = Number(childitem.fGoodsPrice) + Number(
										childitem.carrierDtos[1].fFreightPriceTax) + Number(
										childitem.carrierDtos[1].fMoney)
									childitem.fCarrierID = childitem.carrierDtos[1].fCarrierID
								} else {
									this.isPrice = false
								}
								childitem.fPointPrice = item.fPointPrice; // 积分价格
								childitem.fMaxPointMoney = item.fMaxPointMoney; // 商品最大可使用积分
								childitem.pointRMBPrice = item.pointRMBPrice; // 积分换算率
								childitem.fMinSaleAmount = item.fMinSaleAmount; // 最小销量
								childitem.fAdjustmentRange = item.fAdjustmentRange; // 销量调整幅度
								childitem.fPrecision = item.fPrecision; // 销量小数位数
								// fIsEnableUseCoupon 0无法使用 1可以使用优惠券
								childitem.fIsEnableUseCoupon = item.fIsEnableUseCoupon;
								childitem.fUsePoint = this.calculationEndBalance(
									childitem); // 用户使用积分数默认最大积分
								this.inputBlur(childitem, childindex);
							}
						});
					});
				};
				// console.log('this.orderList', this.orderList,'222222222222');
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				// 获取商品优惠券
				this.getCouponInfo(res);
			},

			datedifference(sDate1, sDate2) { //sDate1和sDate2是2006-12-18格式 
				var dateSpan,
					tempDate,
					iDays;
				sDate1 = Date.parse(sDate1);
				sDate2 = Date.parse(sDate2);
				dateSpan = sDate2 - sDate1;
				dateSpan = Math.abs(dateSpan);
				iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
				return iDays
			},

			//获取优惠券
			async getCouponInfo(res) {
				let orderIds = [];
				this.useGoodsInformation.forEach((item, index) => {
					orderIds.push(-1)
				})
				let couponsDtos = [];
				this.initCouponList = [];
				this.useCouponList = [];
				if (res) {
					res.obj.fCoupon.forEach((couponItem, couponIndex) => {
						if (couponItem.fReceiveStatus == 1) {
							// 是否被使用
							couponItem.isUse = false;
							// 那个订单在使用
							couponItem.useOrder = null;
							if (couponItem.fCouponCustomerReceiveRecordID != '-1') {
								couponsDtos.push(couponItem);
								this.initCouponList.push(couponItem);
								this.useCouponList.push(couponItem);
							} else {
								couponItem.fCouponName = '暂无可用';
							}
						}
					});
					this.orderList.forEach((item, index) => {
						console.log('item', item);
						// item.fCouponCustomerReceiveRecordID = res.obj[res.obj.length - 1].fCouponCustomerReceiveRecordID
						if (item.fIsEnableUseCoupon == 0) {
							// 优惠券ID
							// listItem.fCouponCustomerReceiveRecordID = res.obj[res.obj.length - 1].fCouponCustomerReceiveRecordID;
							item.fCouponCustomerReceiveRecordID = "暂无可用";
						} else if (item.fIsEnableUseCoupon == 1 && res.obj.fCoupon.length > 1) {
							// item.fCouponCustomerReceiveRecordID = '暂不使用';
							let fitterData = couponsDtos.filter(filterItem => filterItem.fMinSaleMoney <= item
								.fGoodsPrice * item.fPlanAmount &&
								filterItem.isUse == false)
							console.log('fitterData', fitterData);
							if (fitterData.length > 0) {
								item.couponList = [fitterData[fitterData.length - 1]];
								item.fCouponCustomerReceiveRecordID = fitterData[fitterData.length - 1]
									.fCouponCustomerReceiveRecordID;
								item.fDeductionMoney = fitterData[fitterData.length - 1].fDeductionMoney;
								console.log('item.couponList111', item.couponList);
							} else {
								item.couponList = fitterData;
								item.fCouponCustomerReceiveRecordID = "暂无可用";
								item.fDeductionMoney = 0;
								console.log('item.couponList222', item.couponList);
							}
							couponsDtos.forEach(childItem => {
								if (item.fCouponCustomerReceiveRecordID == childItem
									.fCouponCustomerReceiveRecordID) {
									childItem.isUse = true;
									childItem.useOrder = index;
								}
							});
						} else {
							item.fCouponCustomerReceiveRecordID = "暂无可用";
						}
						// item.fCouponCustomerReceiveRecordID = res.obj.length - 1 + '张可用优惠券'
					})
				}
				// 共通优惠券列表
				this.couponList = couponsDtos;
				console.log('couponsDtos', couponsDtos);
				this.orderList.forEach((item) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					if (this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
							item.fCouponCustomerReceiveRecordID) != -1) {
						this.useCouponList.splice(this.useCouponList.findIndex(findItem => findItem
							.fCouponCustomerReceiveRecordID ==
							item.fCouponCustomerReceiveRecordID), 1);
					}
					console.log('this.useCouponList111', this.useCouponList);
				})
				this.orderList.forEach((item) => {
					item.couponList = item.couponList.concat(this.useCouponList);
					const hash = {};
					item.couponList = item.couponList.reduce((item, next) => {
						hash[next.fCouponCustomerReceiveRecordID] ? '' : hash[next
								.fCouponCustomerReceiveRecordID] = true && item
							.push(
								next);
						return item;
					}, [])
				})

				console.log('this.orderList', this.orderList);
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				console.log('this.useCouponList', this.useCouponList);
				console.log('this.initCouponList', this.initCouponList);
			},

			//优惠券点击事件
			couponClick(fCouponCustomerReceiveRecordID, index) {
				console.log('this.orderList1212', this.orderList);
				if (fCouponCustomerReceiveRecordID) {
					console.log('lalala', fCouponCustomerReceiveRecordID);
					// couponClick(id, val, index) {
					console.log('index', index);
					this.orderList[index].couponList.forEach(item => {
						if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
							item.isUse = true;
							item.useOrder = index;
							console.log('this.orderList[index].couponList11', this.orderList[index].couponList);
						} else {
							console.log('this.orderList[index].couponList12', this.orderList[index].couponList);
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								this.useCouponList.push(item);
							}
						}
						console.log('this.orderList[index].couponList13', this.orderList[index].couponList);
					});
					let fMinSaleMoney = null;
					this.couponList.forEach(item => {
						if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
							fMinSaleMoney = item.fMinSaleMoney;
							item.isUse = true;
							item.useOrder = index;
						} else {
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								// this.useCouponList.push(item);
							}
						}
					})
					if (this.orderList[index].fGoodsPrice * this.orderList[index].fPlanAmount < fMinSaleMoney) {
						console.log('this.orderList[index].couponList', this.orderList[index].couponList);
						this.couponList.forEach(item => {
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								// this.useCouponList.push(item);
							}
						})
						this.orderList[index].couponList.forEach(item => {
							console.log('this.orderList[index].couponList', this.orderList[index].couponList);
							if (item.useOrder == index) {
								item.isUse = false;
								item.useOrder = null;
								this.useCouponList.push(item);
							}
						});
						this.orderList[index].fCouponCustomerReceiveRecordID = "暂不使用";
						this.orderList[index].fDeductionMoney = 0; //优惠券可抵扣金额
						this.$message('此优惠券不满足优惠条件，请选择其他优惠券');
						return;
					}
					let id1 = "";
					let findData = this.orderList[index].couponList.find(findItem => findItem
						.fCouponCustomerReceiveRecordID ==
						fCouponCustomerReceiveRecordID)
					if (findData) {
						// this.orderList[index].useCouponsDtos = [findData];
						id1 = findData.fCouponCustomerReceiveRecordID
					} else {
						this.orderList.forEach((item, index1) => {
							if (index != index1 && item.fIsEnableUseCoupon == 1) {
								item.couponList.push(findData)
							}
						})
					}
					this.orderList.forEach((item, index1) => {
						if (index != index1) {
							let findData = item.couponList.findIndex(findItem => findItem
								.fCouponCustomerReceiveRecordID == id1)
							console.log('findData', findData);
							if (findData != -1) {
								item.couponList.splice(findData, 1)
							}
						}
					})

					this.orderList.forEach((item, index1) => {
						if (item.couponList.length == 0) {
							item.fCouponCustomerReceiveRecordID = "暂无可用";
						} else {
							if (item.fCouponCustomerReceiveRecordID == "暂无可用") {
								item.fCouponCustomerReceiveRecordID = "暂不使用";
								item.fDeductionMoney = 0; //优惠券可抵扣金额
							} else {
								item.fCouponCustomerReceiveRecordID = item.fCouponCustomerReceiveRecordID;
							}
						}
					})

					console.log('fCouponCustomerReceiveRecordID', fCouponCustomerReceiveRecordID);

					// 当前已选优惠券
					if (fCouponCustomerReceiveRecordID) {
						this.orderList[index].fCouponCustomerReceiveRecordID = fCouponCustomerReceiveRecordID;
						this.orderList[index].fDeductionMoney = this.couponList.find(item => item
							.fCouponCustomerReceiveRecordID ==
							fCouponCustomerReceiveRecordID).fDeductionMoney; //优惠券可抵扣金额
					} else {
						console.log('aaaa', index);
						this.orderList[index].fCouponCustomerReceiveRecordID = '暂不使用';
						this.orderList[index].fDeductionMoney = 0; //优惠券可抵扣金额
					}
					console.log('this.orderList111', this.orderList);
				} else {
					this.orderList[index].fCouponCustomerReceiveRecordID = '暂不使用';
					this.orderList[index].fDeductionMoney = 0; //优惠券可抵扣金额
					this.orderList[index].couponList.forEach(item => {
						if (item.isUse == true && item.useOrder == index) {
							item.isUse = false;
							item.useOrder = null;
							this.useCouponList.push(item);
						}
					});
				}

				this.orderList.forEach((item) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					if (this.useCouponList.findIndex(findItem => findItem.fCouponCustomerReceiveRecordID ==
							item.fCouponCustomerReceiveRecordID) != -1) {
						this.useCouponList.splice(this.useCouponList.findIndex(findItem => findItem
							.fCouponCustomerReceiveRecordID ==
							item.fCouponCustomerReceiveRecordID), 1);
					}
					console.log('this.useCouponList123', this.useCouponList);
				})

				this.orderList.forEach((item) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					item.couponList = item.couponList.concat(this.useCouponList);
					const hash = {};
					item.couponList = item.couponList.reduce((item, next) => {
						hash[next.fCouponCustomerReceiveRecordID] ? '' : hash[next
							.fCouponCustomerReceiveRecordID] = true && item.push(
							next);
						return item;
					}, [])
					console.log('item.couponList', item.couponList);
				})


				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				console.log('this.couponList123456', this.couponList);
			},
			// 优惠券清空事件
			couponClear(fCouponCustomerReceiveRecordID, index) {
				this.couponList.forEach(item => {
					if (item.fCouponCustomerReceiveRecordID == fCouponCustomerReceiveRecordID) {
						item.isUse = false;
						item.useOrder = null;
					}
				})
			},

			// 根据商品ID查询当前商品配送方式
			async getDeliveryByAreaid(res) {
				await this.useGoodsInformation.forEach((data, index) => {
					
					// 			res => {
					// 判断当前接口返回配送方式是否是订单所选配送方式
					console.error(res)
					res.obj.fGoodsDeliverys.forEach(item => {
						item.fDeliveryID = item.fDeliveryID.toString()
					})

					if (res.obj.fGoodsDeliverys.some(item => item.fDeliveryID == data.deliveryId)) {
						// 订单选中配送方式判断变量赋值
						this.orderList[index].deliveryMethodData = data.deliveryId.toString();
						this.orderList[index].deliveryMethodDataStatus = Number(data.deliveryId.toString())
						this.orderList[index].fDeliveryID = data.deliveryId.toString();
						this.orderList[index].freightAmount = 0;
					} else {
						if (res.obj.fGoodsDeliverys.length != 0) {
							this.orderList[index].deliveryMethodData = res.obj.fGoodsDeliverys[0].fDeliveryID;
							this.orderList[index].deliveryMethodDataStatus = Number(res.obj.fGoodsDeliverys[0]
								.fDeliveryID)
							this.orderList[index].fDeliveryID = res.obj.fGoodsDeliverys[0].fDeliveryID;
							this.orderList[index].freightAmount = 0;
							this.useGoodsInformation[index].deliveryId = res.obj.fGoodsDeliverys[0].fDeliveryID
						} else {
							this.orderList[index].deliveryMethodData = ""
							this.orderList[index].deliveryMethodDataStatus = ''
							this.orderList[index].freightAmount = 0
							this.orderList[index].fDeliveryID = '-1'
							this.useGoodsInformation[index].deliveryId = '-1'
						}

					}


					// res.obj.fGoodsDeliverys.unshift({
					// 	fDeliveryID: "-1",
					// 	fDelivery: '请选择配送方式'
					// });
					this.orderList[data.index].deliveryDtos = res.obj.fGoodsDeliverys;
					console.log(this.orderList[data.index].deliveryDtos,
						'this.orderList[data.index].deliveryDtos')
					this.getGoodsPrice(res)
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					// 			},
					// 			error => {}
					// 		);
				});
				this.getFreightPrice(res); //承运商数据 需要地址ID
			},

			//配送方式为承运商代送时候承运商数据
			async getFreightPrice(res) {
				let apiData = []
				await this.useGoodsInformation.forEach((item, index) => {
					console.log('item2222', item);
					apiData.push({
						fGovernmenAreaID: item.fGovernmenAreaID,
						fGoodsID: item.goodsId
					})
				});
				console.error(res.obj.freightApply)
				res.obj.freightApply.forEach((data, dataIndex) => {
					for (let item of this.orderList) {
						// 判断接口返回承运商数据是否是订单所选承运商
						if (data.fGoodsID == item.fGoodsID) {
							let carrierDtosData = {
								fCarrierID: -1,
								fCustomerName: '请选择承运商'
							}
							if (data.freightPrice.length == 1 && data.freightPrice[0].fCarrierID == '0') {
								data.freightPrice = [carrierDtosData];
								item.carrierData = -1;
								item.fCarrierID = -1;
								item.carrierDtos = data.freightPrice;
								return;
							}
							item.carrierData = -1;
							item.fCarrierID = -1;
							item.fFreightPriceID = data.freightPrice[0].fFreightPriceID
							item.carrierDtos = data.freightPrice;
							item.carrierDtos.unshift(carrierDtosData);
							break;
						}
					}
				});
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//获取用户积分
			async getCustomerPoint(res) {
				this.originalPoint = res.obj.fPoint.fEndBalance; //原始积分
				this.orderList.forEach((item, index) => {
					item.fEndBalance = res.obj.fPoint.fEndBalance;
					item.fUsePoint = this.calculationEndBalance(item); // 用户使用积分数默认最大积分
					this.inputBlur(item, index);
				});
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//配送方式点击事件（单选框的点击事件）
			deliveryMethodClick(deliveryid, index) {
				console.error(deliveryid, index)
				this.orderList[index].fDeliveryID = deliveryid;
				this.useGoodsInformation[index].deliveryId = deliveryid;
				for (let item of this.orderList) {
					if (item.fDeliveryID == 1) {
						this.isPrice = true
					} else if (item.fDeliveryID == 5) {
						console.log(this.orderList, '查看数组的数据')
						this.isPrice = false
						//循环得到数据的数组；改变其商品价格（把运费和服务费给它加进去）
					} else {
						this.isPrice = false
					}
				}
				this.orderList[index].deliveryMethodData = deliveryid;
				this.orderList[index].deliveryMethodDataStatus = Number(deliveryid)
				// 配送方式变更时 是否运输发票 重置为 否
				this.orderList[index].fIsNeedFInvoiceStatus = 0;
				// 配送方式变更 不是承运商代送时 选择承运商重置为请选择  运价重置为0
				if (deliveryid != 1) {
					this.orderList[index].carrierData = -1;
					this.orderList[index].fCarrierID = -1;
					this.orderList[index].freightAmount = 0;
				}
				console.log(this.orderList)
				console.log(this.useGoodsInformation[index])
				this.getGoodsPrice(this.orderListData)
				console.log(this.useGoodsInformation, '查看里面获取的配送方式的id')
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//承运商点击事件
			carrierClick(fCarrierID, index) {
				this.fFreightPriceTax = 0;
				let fMinAmount = 0;
				let fFreightPriceTax = 0;
				this.orderList[index].carrierDtos.forEach((item, index) => {
					// 选择承运商 拿取最小运载量 运输价格
					if (fCarrierID == item.fCarrierID) {
						fMinAmount = item.fMinAmount;
						if (item.fFreightPriceTax) {
							fFreightPriceTax = item.fFreightPriceTax;
						}
					}
				});
				this.fFreightPriceTax = fFreightPriceTax;
				if (this.orderList[index].fPlanAmount < fMinAmount) {
					this.$message('当前承运商最小运输数量为' + fMinAmount);
					this.orderList[index].carrierData = -1;
					this.orderList[index].freightAmount = 0;
					this.$forceUpdate();
					return;
				}
				this.orderList[index].freightAmount = fFreightPriceTax * this.orderList[index].fPlanAmount;
				this.orderList[index].fCarrierID = fCarrierID;
				this.orderList[index].carrierData = fCarrierID;
				this.orderList[index].carrierDtos.forEach((item, index1) => {
					if (item.fCarrierID == fCarrierID) {
						this.orderList[index].fFreightPriceTax = item.fFreightPriceTax;
						this.orderList[index].fIsNeedFInvoiceStatus = 0;
					}
				})
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//是否需要运输发票点击事件
			transportationInvoiceClick(fIsNeedFInvoiceStatus, index) {
				if (this.orderList[index].fCarrierID == -1) {
					this.orderList[index].fIsNeedFInvoiceStatus = 0;
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					this.$message('请选择承运商');
				} else {
					this.orderList[index].fIsNeedFInvoiceStatus = fIsNeedFInvoiceStatus;
					if (fIsNeedFInvoiceStatus == 1) {
						this.orderList[index].freightAmount = this.orderList[index].fFreightPriceTax * this.orderList[
							index].fPlanAmount;
					} else {
						this.orderList[index].freightAmount = this.orderList[index].fFreightPriceTax * this.orderList[
							index].fPlanAmount;
					}

					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
				}
			},

			inputInput(val, index) {
				this.orderList[index].fUsePoint = val.toString().replace(/[^\d]/g, '');
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			//积分输入框失焦事件
			inputBlur(orderData, i) {

				// 商品可使用积分 = 商品价格（商品积分价 fPointPrice * 商品数量 fPlanAmount ）和用户积分余量 fEndBalance 那个值小用哪个
				let canUsePoints = (orderData.fPointPrice * orderData.fPlanAmount) > orderData.fEndBalance ? orderData
					.fEndBalance : (orderData.fPointPrice * orderData.fPlanAmount)
				// 当用户输入积分 不可大于 商品可使用积分
				if (orderData.fUsePoint > canUsePoints) {
					this.orderList[i].fUsePoint = canUsePoints;
				}
				this.orderList.forEach((item, index) => {
					if (index == i) {
						// 积分抵扣金额 = 用户输入积分 * 积分换算率
						if (orderData.fUsePoint) {
							item.deductionMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						} else {
							orderData.fUsePoint = 0;
							item.deductionMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						}
						console.log('item.deductionMoney', item.deductionMoney);
					}
				});
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},

			addInvoiceClose() {
				this.addInvoiceStatus = false;
				this.addInvoiceForm = {};
			},
			addInvoice(type) {
				this.invoiceType = type;
				if (type == 'Edit') {
					this.getInvoiceAddress(this.orderListData);
				}
				this.addInvoiceStatus = true;
			},
			DeleteAddress(item, index) {
				for (var i = 0; i < this.addressTol.length; i++) {
					if (i == index) {
						this.addressTol.splice(index, index);
					}
				}
			},
			DeleteAddressChecked() {
				// this.address = {};
			},
			//展开关闭其他地址
			noDefaultAddressShow(index) {
				this.addressIndex = index;
				this.addressStatus = !this.addressStatus;
			},
			// 商品数量进步器 光标消失时调用
			valBlur(orderData, index) {
				if (!orderData.fPlanAmount) {
					this.orderList[index].fPlanAmount = 0
				}
			},
			// 数量加减 变化时
			valChange(orderData, index) {
				console.log('数量加减orderData', orderData);
				let fMinAmount = 0;
				let fFreightPrice = 0;
				this.orderList[index].carrierDtos.forEach(item => {
					if (orderData.carrierData == item.fCarrierID && item.fCarrierID != '-1') {
						fMinAmount = item.fMinAmount;
						if (orderData.fIsNeedFInvoiceStatus == 0) {
							this.orderList[index].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
						} else {
							this.orderList[index].freightAmount = item.fFreightPriceTax * orderData.fPlanAmount;
						}

						this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					}
				});
				if (this.orderList[index].fPlanAmount < fMinAmount) {
					this.$message('当前承运商最小运输数量为' + fMinAmount + ',请重新选择承运商');
					this.orderList[index].carrierData = -1;
					this.orderList[index].fCarrierID = -1;
					this.orderList[index].freightAmount = 0;
					this.$forceUpdate(); //解决动态该数据之后渲染数据慢
					return;
				}

				let goodsPrice = this.orderList[index].fPlanAmount * this.orderList[index].fGoodsPrice;
				console.log('goodsPrice', goodsPrice);
				console.log('this.orderList[index].fIsEnableUseCoupon', this.orderList[index].fIsEnableUseCoupon);
				if (this.orderList[index].fIsEnableUseCoupon != 0) {
					console.log('this.couponList', this.couponList);
					for (let item of this.couponList) {
						// 当货款价格大于当前优惠券最小消费金额 且 当前可选
						if (goodsPrice < item.fMinSaleMoney && this.orderList[index].fCouponCustomerReceiveRecordID == item
							.fCouponCustomerReceiveRecordID) {
							this.orderList[index].fCouponCustomerReceiveRecordID = "暂不使用";
							this.orderList[index].fCouponMoney = 0;
							item.isUse = false;
							item.useOrder = null;
							this.useCouponList.push(item);
							break;
						}
					}
				}
				console.log('this.useCouponList', this.useCouponList);
				this.orderList.forEach((item, i) => {
					// item.couponList = JSON.parse(JSON.stringify(couponsDtos))
					item.couponList = item.couponList.concat(this.useCouponList);
					const hash = {};
					item.couponList = item.couponList.reduce((item, next) => {
						hash[next.fCouponCustomerReceiveRecordID] ? '' : hash[next
							.fCouponCustomerReceiveRecordID] = true && item.push(
							next);
						return item;
					}, [])
					console.log('item.couponList', item.couponList);
					if (index == i) {
						item.fUsePoint = this.calculationEndBalance(item); // 用户使用积分数默认最大积分
						if (orderData.fUsePoint) {
							item.deductionMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						} else {
							orderData.fUsePoint = 0;
							item.deductionMoney = parseInt(orderData.fUsePoint) * orderData.pointRMBPrice;
						}
					}
				})
				this.orderList[index].couponList.forEach(item => {
					if (item.useOrder == index) {
						item.isUse = false;
						item.useOrder = null;
					}
				});
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢

				// 编辑数量时，判断可用优惠券 自动选上
				// 货款价格
			},
			//计数器获得焦点全选内容
			getInputFocus(event) {
				event.currentTarget.select();
			},
			// 选择收货地址
			addressChoose(obj, activeindex) {
				this.chooseAddress = obj;
				this.orderList[activeindex].address = obj;
				this.orderList[activeindex].addressId = obj.fAddressID;
				this.orderList[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				this.orderList[activeindex].obj = {}
				this.orderList[activeindex].obj.fGoodsDeliverys = this.orderList[activeindex].deliveryDtos;
				this.useGoodsInformation[activeindex].fGovernmenAreaID = obj.fGovernmenAreaID;
				console.error(this.orderList[activeindex])
				// this.getDeliveryByAreaid(this.orderList[activeindex]);
				// this.getGoodsPrice();//调用价格的接口
				this.ApiRequestPostNOMess('/api/mall/ebsale/order/new-switch-address-logic', {
						addressTypeId: this.orderList[activeindex].address.fAddressTypeID,
						fGoodsId: [this.orderList[activeindex].fGoodsID],
						fGovernmenAreaID: this.useGoodsInformation[activeindex].fGovernmenAreaID
					})
					.then(
						res => {
							// 判断商品是否在销售
							if (res.obj.length <= 0) {
								this.$message('当前地址下商品不支持销售,请重新选择地址！');
							} else {
								res.obj.items = res.obj.fAddress
								res.obj.goodList = []
								res.obj.fGoodsDeliverys = []
								res.obj.freightApply = []
								res.obj.goodsPrice = []
								res.obj.fGoodsList.map(item => {
									res.obj.goodList.push(item.fGoods)
									item.fGoodsDeliverys.map(cell => {
										if (cell.fPriceDelivery && cell.fPriceDelivery != '') {
											res.obj.fGoodsDeliverys.push(cell.fPriceDelivery)
										}
										if (cell.freightApply && cell.freightApply != '') {
											res.obj.freightApply.push(cell.freightApply)
										}
										if (cell.fPrice && cell.fPrice != '') {
											res.obj.goodsPrice.push(cell.fPrice)
										}
									})
								})
								res.obj.fTransport = res.obj.fGoodsList[0].fTransport
								this.getProjectList(res)
							}
						},
						error => {}
					);
				this.$forceUpdate(); //解决动态该数据之后渲染数据慢
			},
			//议价的询问验证
			talkAboutPrice() {
				this.$confirm('是否确认该笔订单与承运商议价?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.speackPrice()
				}).catch(() => {});
			},
			//承运商议价
			asktalkAboutPrice() {
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				for (let item of this.orderList) {

					if (item.deliveryDtos.length > 0) {
						for (let item1 of item.deliveryDtos) {
							if (item1.fDeliveryID == -1) {
								this.$message('商品不在当前收货地址销售，请重新选择收货地址');
								return;
							}
						}
					} else {
						this.$message('商品不在当前收货地址销售，请重新选择收货地址');
						return;
					}
					if (item.fDeliveryID != 1) {
						this.$message('订单中存在不可以议价的订单，请确认订单详细情况');
						return;
					}

				}
				if (findData) {
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.fCarrierID == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				// 提交参数
				this.submitListPrice = [];
				// 提交成功数组
				this.submitSuccessListPrice = [];
				this.orderList.forEach((item, index) => {
					if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID ==
						'暂无可用') {
						item.fCouponCustomerReceiveRecordID = -1;
					}
					console.log(item, '555555')
					let params = {
						fGoodsID: item.fGoodsID,
						fAddressID: item.address.fAddressID,
						fUsePoint: item.fUsePoint,
						fDeliveryID: item.fDeliveryID, // 配送方式ID
						fCarrierID: item.fCarrierID, // 承运商ID
						fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID == null ? -1 : item
							.fCouponCustomerReceiveRecordID, // 优惠券ID
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
						fPlanAmount: item.fPlanAmount, // 商品数量
						fAppTypeID: this.fAppTypeID, // 应用类型ID
						fBusinessTypeID: item.fBusinessTypeID, // 运输方式ID
						fcFreightPrice: item.fFreightPriceTax,
						fFreightPriceID: item.fFreightPriceID
					}
					this.submitListPrice.push(params);
				});
				this.talkAboutPrice();

			},
			//调用承运商议价的接口
			async speackPrice() {
				let forlength = this.submitListPrice.length;
				let forIndex = JSON.parse(JSON.stringify(this.needPriceIndex))
				this.orderLoading = true
				for (let index = forIndex; index < forlength; index++) {
					let item = this.submitListPrice[index]
					// 异步调用API方法，以达到对成功的订单分段控制
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create-bargaining-order', item)
						.then(
							res => {
								console.log(res)
                if (res.obj !== null) {
                  this.needPriceIndex = index + 1;
                  this.resultdata.push(res.obj)
                  if (this.needPriceIndex == this.submitListPrice.length) {
                    var colleteList = []
                    for (let item of this.resultdata) {
                      colleteList.push({
                        fOrderNumber: item.fOrderNumber,
                        fCarrierName: item.fCarrierName,
                        fCarrierPhone: item.fCarrierPhone,
                      })
                    }
                    console.log(colleteList)
                    // 跳转到议价单页面
                    this.goToUnderReview(colleteList);
                  }
								} else {
                  let str = res.message
                  let arr = str.split(';')
                  console.log(arr)
                  if (arr[1] === '001' || arr[1] === '002' || arr[1] === '003') {
                    this.$confirm(createMessage + ', 是否继续?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                    }).then(() => {
                      if (arr[1] == '001') {
                        this.$store.dispatch("setShowAttestation", true);
                      } else if (arr[1] == '002') {
                        this.addInvoice('Add');
                      } else if (arr[1] == '003') {
                        if (this.getUserInfo.fCommerceTypeID == '1') {
                          this.$router.push({
                            path: '/businessme/individualUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转个人用户绑卡
                        } else if (this.getUserInfo.fCommerceTypeID == '2') {
                          this.$router.push({
                            path: '/businessme/enterpriseUsersBindingCard',
                            query: {
                              title: '开通账服通',
                              type: 2,
                              fAccountname: '账服通'
                            }
                          }) //跳转企业用户绑卡
                        }
                      }
                    })
                  } else {
                    this.$message(arr[0])
                  }
                  // if (arr[arr.length-1] === '001')
                  this.orderLoading = false
                }
								

							},
							error => {
                this.$message.error(error)
                this.orderLoading = false
              }
						);
				}
			},
			//跳转审核中页面
			goToUnderReview(val) {
				console.log('议价审核中');
				// this.$router.push("/about/Allperfect");
				this.orderLoading = false
				this.$router.push({
					name: "bargainPrice",
					params: {
						fname: JSON.stringify(val)
					},
				});
			},

			//结算创建订单
			createOrder() {
				console.log('this.orderList', this.orderList);
				var falg = false;
				this.orderList.forEach((val, index) => {
					if (val.fStatus == 2) {
						falg = true;
					}
				});
				if (falg == true) {
					this.$message('亲，当前商品已售罄，无法购买');
					return
				}
				var falgcontinue = false;
				this.orderList.forEach((val, index) => {
					if (val.fGoodsPrice != val.fGoodsMinPrice) {
						falgcontinue = true;
					}
				});
				if (falgcontinue == true) {
					this.$message('商品不在当前收货地址销售，请重新选择收货地址！');
					return
				}
				let findData = this.orderList.find(findItem => JSON.stringify(findItem.address) == '{}')
				for (let item of this.orderList) {
					if (item.deliveryDtos.length > 0) {
						for (let item1 of item.deliveryDtos) {
							if (item1.fDeliveryID == -1) {
								this.$message('商品不在当前收货地址销售，请重新选择收货地址');
								return;
							}
						}
					} else {
						this.$message('商品不在当前收货地址销售，请重新选择收货地址');
						return;
					}

				}
				if (findData) {
					// this.$message('请维护地址或者选择地址！');
					this.addAddress();
					return;
				}
				if (this.fIsNeedInvoiceStatus == 1 && this.invoiceInformation.feMail == null) {
					// this.$message('请维护发票收件地址');
					this.addInvoice('Add');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == 1 && p.fCarrierID == -1)) {
					this.$message('请选择承运商');
					return;
				}
				if (this.orderList.some(p => p.fDeliveryID == -1)) {
					this.$message('请选择配送方式');
					return;
				}
				if (this.orderList.some(p => p.fBusinessTypeID == 0 || p.fBusinessTypeID == '')) {
					this.$message('请选择运输方式');
					return;
				}
				// 提交参数
				this.submitList = [];
				// 提交成功数组
				this.submitSuccessList = [];
				this.orderList.forEach((item, index) => {
					if (item.fCouponCustomerReceiveRecordID == '暂不使用' || item.fCouponCustomerReceiveRecordID ==
						'暂无可用') {
						item.fCouponCustomerReceiveRecordID = -1;
					}
					let params = {
						fGoodsID: item.fGoodsID,
						fAddressID: item.address.fAddressID,
						fUsePoint: item.fUsePoint,
						fDeliveryID: item.fDeliveryID, // 配送方式ID
						fCarrierID: item.fCarrierID, // 承运商ID
						fCouponCustomerReceiveRecordID: item.fCouponCustomerReceiveRecordID == null ? -1 : item
							.fCouponCustomerReceiveRecordID, // 优惠券ID
						fIsNeedFInvoiceStatus: item.fIsNeedFInvoiceStatus, // 运输发票
						fIsNeedInvoiceStatus: this.fIsNeedInvoiceStatus, // 必须要发票
						fPlanAmount: item.fPlanAmount, // 商品数量
						fAppTypeID: this.fAppTypeID, // 应用类型ID
						fBusinessTypeID: item.fBusinessTypeID, // 运输方式ID
					}
					this.submitList.push(params);
				});
				this.submitOrderList();
			},
			// 异步【递归】提交订单方法
			async submitOrderList() {
				let forlength = this.submitList.length;
				let forIndex = JSON.parse(JSON.stringify(this.submitListIndex))
				// 提交订单是否成功
				let isSuccsss = true;
				this.createLoading = true;
				for (let index = forIndex; index < forlength; index++) {
					let item = this.submitList[index]
					// 异步调用API方法，以达到对成功的订单分段控制
					await this.ApiRequestPostNOMess('api/mall/ebsale/order/create', item)
						.then(
							res => {
								this.createLoading = false;
								// 记录已提交数据下标
								this.submitListIndex = index + 1;
								// res.message 为空时 视为提交成功。
								// res.message 不为空时 视为提交失败。
								if (res.message == "") {
									if (res.obj == null) {
										this.$message('');
										return;
									} else {
										this.submitSuccessList.push(res.obj)
									}
								} else {
									this.orderList.forEach((item, index) => {
										item.fCouponCustomerReceiveRecordID = "暂不使用";
									})
									isSuccsss = false;
									// 如果失败则提示用户是否继续
									res.message = res.message.split(';');
									let createTipCode = res.message[1];
									let createMessage = res.message[0];
									console.log('createTipCode', createTipCode);
									this.$confirm(createMessage + ', 是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {
										if (createTipCode == '001') {
											this.$store.dispatch("setShowAttestation", true);
										} else if (createTipCode == '002') {
											this.addInvoice('Add');
										} else if (createTipCode == '003') {
											if (this.getUserInfo.fCommerceTypeID == '1') {
												this.$router.push({
													path: '/businessme/individualUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转个人用户绑卡
											} else if (this.getUserInfo.fCommerceTypeID == '2') {
												this.$router.push({
													path: '/businessme/enterpriseUsersBindingCard',
													query: {
														title: '开通账服通',
														type: 2,
														fAccountname: '账服通'
													}
												}) //跳转企业用户绑卡
											}
										} else if (createTipCode == '004') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										} else if (createTipCode == '005') {
											this.getCustomerPoint();
										} else if (createTipCode == '006') {
											this.submitListIndex = 0;
											this.submitList = []
											this.submitSuccessList = [];
										}
									}).catch(() => {
										// 取消则重置记录数据
										this.submitListIndex = 0;
										this.submitList = []
										this.submitSuccessList = [];
									});
								}
							},
							error => {
								isSuccsss = false;
								this.createLoading = false;
							}
						);
					// 提交订单失败则停止循环
					if (isSuccsss == false) {
						break;
					}
				}
				// 判断在当前方法结束时，订单是否已全部提交API以及是否有成功提交的订单
				if (this.submitListIndex == this.submitList.length && this.submitSuccessList.length > 0) {
					// 已全部提交API 且有成功提交的订单 跳转支付单
					this.jumpPayList();
				}
			},
			// 跳转支付单
			async jumpPayList() {
				// 组织订单ID数组
				let orderIds = []
				console.log('this.submitSuccessList', this.submitSuccessList);
				await this.submitSuccessList.forEach(item => {
					orderIds.push(item.fOrderID.toString())
				})
				// 跳转  replace :此方法跳转不会记录当前页面。
				sessionStorage.setItem('fDeliveryID', this.orderList[0].fDeliveryID);
				this.$router.replace({
					name: "PaymentOrder",
					params: {
						orderIds: orderIds,
						fBillTypeID: this.submitSuccessList[0].fBillTypeID
					}
				})
			},

			//获取当前位置信息
			getMyAddress() {
				let _this = this;
				this.mapShow = true;

				this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?address=' + this.addr +
						'&key=PM5BZ-2GP6S-7GHO5-6SIIX-DZD2S-RUB7S&output=jsonp', {})
					.then(json => {
						// alert(JSON.stringify(json.result.location));
						_this.maplat = json.result.location.lat;
						_this.maplng = json.result.location.lng;
						// 初始化地图,这里的1秒延迟是必要的,否则不能正常渲染出来 
						setTimeout(function() {
							_this.initMap()
						}, 1000);

					})
					.catch(err => {
						alert(JSON.stringify(err));
					});
			},
			//初始化地图
			initMap() {
				//定义map变量，调用 TMap.Map() 构造函数创建地图
				// "lat": 43.794399第一个参数
				// "lng": 87.631676,第二个参数
				var center = new TMap.LatLng(this.maplat, this.maplng); //设置中心点坐标
				//初始化地图
				var map = new TMap.Map(container, {
					center: center
				});
				/* 这个就是那个地图上的指针点 */
				//创建并初始化MultiMarker
				var markerLayer = new TMap.MultiMarker({
					map: map, //指定地图容器
					//样式定义
					styles: {
						//创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
						myStyle: new TMap.MarkerStyle({
							width: 25, // 点标记样式宽度（像素）
							height: 35, // 点标记样式高度（像素）
							//src: '../img/marker.png', //自定义图片路径
							//焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
							anchor: {
								x: 16,
								y: 32
							}
						})
					},
					//点标记数据数组
					geometries: [{
						id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
						styleId: 'myStyle', //指定样式id
						position: new TMap.LatLng(this.maplat, this.maplng), //点标记坐标位置
						properties: {
							//自定义属性
							title: 'marker1'
						}
					}]
				});
			},
			//新增地址
			addAddress() {
				this.pageTitle = "新增";
				this.addVisible = true;
			},
			//编辑地址
			EditAddress(item) {
				this.pageTitle = "编辑";
				this.ApiRequestPostNOMess("api/mall/eb-customer/address/get", {
						id: item.fAddressID
					})
					.then(res => {
						res.obj.fIsDefault = res.obj.fIsDefault == 0 ? false : true
						res.obj.fFullPathAddress = res.obj.fFullPathAddress.replace(/\\/g, '');
						res.obj.area = ['', '', '', ''];
						res.obj.area[0] = res.obj.fFullIDPath.substring(1, 3);
						res.obj.area[1] = res.obj.fFullIDPath.substring(4, 8);
						res.obj.area[2] = res.obj.fFullIDPath.substring(9, 15);
						console.log(res.obj.area)
						res.obj.area[3] = res.obj.fFullIDPath.substring(16, 29);
						this.addressForm.area = res.obj.area
						this.addressForm = res.obj;
						console.log(res.obj.area)
						this.addVisible = true;
						this.$forceUpdate()
					}, error => {});
			},
			//新增或者编辑地址
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let areaN = this.addressForm.area;
						this.addressForm.fGovernmenAreaID = areaN[areaN.length - 1];
						if (this.pageTitle == "编辑") {
							this.addressForm.fIsDefault = this.addressForm.fIsDefault == true ? 1 : 0;
							this.addressForm.rowStatus = 16;
							this.ApiRequestPut('/api/mall/eb-customer/address/update', this.addressForm).then(
								result => {
									this.getList();
									this.handleClose();
								},
								rej => {}
							);
						} else if (this.pageTitle == "新增") {
							this.ApiRequestPost('/api/mall/eb-customer/address/create', {
								fAddressTypeID: 1,
								fCustomerID: 0,
								feMail: this.addressForm.feMail,
								fGovernmenAreaID: this.addressForm.fGovernmenAreaID,
								fAddress: this.addressForm.fAddress,
								flng: 0,
								flat: 0,
								fLinkman: this.addressForm.fLinkman,
								fLinkPhone: this.addressForm.fLinkPhone,
								fRemark: "",
								fStatus: 1,
								fIsDefault: this.addressForm.fIsDefault == true ? 1 : 0,
								fProjectName: this.addressForm.fProjectName,
								fLastUseTime: "",
								fCreatorUserID: 0,
								rowStatus: 4
							}).then(
								result => {
									this.getList();
									this.handleClose();
								},
								rej => {}
							);
						}
					} else {
						return false;
					}
				});
			},
			handleClose() {
				this.addVisible = false;
				this.resetForm();
			},
			//重置form表单
			resetForm() {
				this.addressForm = {
					fLinkman: '',
					fLinkPhone: '',
					area: [],
					fAddress: '',
					fProjectName: '',
					feMail: '',
					fAddressTypeID: '',
					fIsDefault: false
				}
			},

			newInvoice() {
				this.ApiRequestPostNOMess('/api/mall/ebsale/order/new-switch-address-logic', {
					addressTypeId: 1,
					fGoodsId: [this.orderList[0].fGoodsID],
					fGovernmenAreaID: -1
				}).then(res => {
					console.error(res)
					res.obj.finvoiceAddress.area = ['', '', '', ''];
					res.obj.finvoiceAddress.area[0] = res.obj.finvoiceAddress.fFullIDPath.substring(1, 3);
					res.obj.finvoiceAddress.area[1] = res.obj.finvoiceAddress.fFullIDPath.substring(4, 8);
					res.obj.finvoiceAddress.area[2] = res.obj.finvoiceAddress.fFullIDPath.substring(9, 15);
					res.obj.finvoiceAddress.area[3] = res.obj.finvoiceAddress.fFullIDPath.substring(16, 29);
					this.invoiceInformation.fLinkman = res.obj.finvoiceAddress.fLinkman;
					this.invoiceInformation.fLinkPhone = res.obj.finvoiceAddress.fLinkPhone;
					this.invoiceInformation.feMail = res.obj.finvoiceAddress.feMail;
					this.addInvoiceForm = res.obj.finvoiceAddress;
					this.invoiceInformation.fFullPathAddress = res.obj.finvoiceAddress.fFullPathAddress.replace(
						/\\/g, '');
					this.orderListData.obj.finvoiceAddress = res.obj.finvoiceAddress;
					this.$forceUpdate()
				}, rej => {
					this.$forceUpdate()
				})
			},

			//保存收件信息
			invoiceSubmitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.invoiceType == 'Add') {
							this.addInvoiceForm.fAddressID = 0;
						}
						/* this.addInvoiceForm.fGovernmenAreaID = this.addInvoiceForm.area[this.addInvoiceForm.area.length-1];
						console.log(this.addInvoiceForm); */
						this.ApiRequestPost('/api/mall/eb-customer/address/create-invoice-address', {
							fAddressID: this.addInvoiceForm.fAddressID,
							feMail: this.addInvoiceForm.feMail,
							fGovernmenAreaID: this.addInvoiceForm.area[this.addInvoiceForm.area.length -
								1],
							fAddress: this.addInvoiceForm.fAddress,
							fLinkman: this.addInvoiceForm.fLinkman,
							fLinkPhone: this.addInvoiceForm.fLinkPhone
						}).then(
							result => {
								console.log(result);
								console.log(this.invoiceInformation)
								console.log(this.addInvoiceForm)
								console.error(this.addInvoiceForm.area.toString())
								if (result.code == "HengHeCode1002") {
									this.newInvoice()
								}
								this.addInvoiceStatus = false
								this.$forceUpdate()
							},
							rej => {
								this.addInvoiceStatus = false
								this.$forceUpdate()
							}
						);
					} else {
						return false;
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-border-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box {
		padding: 0 3px;
		background-image: linear-gradient(to right top, #FF0000, #FF9999);
	}

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width756 {
		width: 756px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 80px;
		background: #f8f9fe;
		width: 100%;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		width: 1070px;
		height: 50px;

		margin: 15px;
		position: relative;
		// transition: all 0.5s ease;
		// &::after {
		// 	content: '✔';
		// 	display: block;
		// 	height: 0px;
		// 	width: 0px;
		// 	position: absolute;
		// 	bottom: 0;
		// 	right: 0;
		// 	color: #fff;
		// 	font-size: 14px;
		// 	line-height: 18px;
		// 	border: 18px solid;
		// 	// border-color: transparent #55aaff #55aaff transparent;
		// 	@include themify($themes) {
		// 		border-color: transparent themed('themes_color') themed('themes_color') transparent;
		// 	}
		// }
	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;
		// border: 2px solid #dfdfdf;
	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-box .el-input__inner {
		border-radius: 0;
		width: 140px;
		// height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 138px;
		height: 28px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #f2f2f2;
	}

	/* 承运商 */
	.carrier {
		// width: 100%;
	}

	::v-deep .carrier .el-input__inner {
		width: 255px;
	}

	::v-deep .invoice .el-input__inner {
		width: 321px;
	}

	::v-deep .el-cascader {
		width: 321px;
	}

	/* 虚线 */
	.divcss5-3 {
		border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
		margin-top: 10px;
	}

	.hover:hover {
		text-decoration: underline;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-dialog__body {
		padding-top: 5px;
	}

	.line-height22 {
		line-height: 22px;
	}
</style>
